.container {
  padding: 40px 0;
}

.heading {
  text-align: center;
  margin-bottom: 20px;
}

.subHeading {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.aboutText {
  text-align: center;
  font-size: 1.1rem;
}

.skillCard {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.card {
  background-color: #f8f9fa;
  border: none;
  width: 150px; /* Fixed width */
  height: 150px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cardBody {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.skillTitle {
  text-align: center;
  font-size: 18px;
}

.button {
  width: 100%; /* Ensure both buttons are full width */
  padding: 10px 0; /* Adjust padding as needed */
}

.footer {
  background-color: black;
  color: white;
  border-top: 1px solid white;
  padding: 5px 0;
  text-align: center;
  width: 100%;
  position: relative;
  margin-top: auto;
}

.row {
  color: white;
  font-size: 1.5rem;
  text-decoration: none;
  margin: 0 10px;
}

.row a {
  color: white;
}

.row a:hover {
  opacity: 0.8;
}

.mainContent {
  flex: 1;
  padding: 20px;
  color: white;
  min-height: 100vh;
  background-color: #020302;
}

.text p {
  font-size: 1.2em;
}

.text h2 {
  font-size: 2em;
}

.buttonLink {
  display: block;
  width: 100%; /* Ensure the Link takes full width of its parent */
  margin-bottom: 15px; /* Add some space between buttons */
}

.button {
  width: 100%; /* Ensure both buttons are full width */
  padding: 10px 0; /* Adjust padding as needed */
}

@media (max-width: 768px) {
  .text p {
    font-size: 0.8em;
  }

  .text h2 {
    font-size: 1em;
  }

  .image img {
    width: 80%;
  }
}

.container {
  background-color: black;
  color: white;
  padding: 20px;
  background-size: 400% 400%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.h1 {
  text-align: center;
  margin-bottom: 40px;
}

.h2 {
  text-align: center;
  margin-bottom: 20px;
}

.card {
  background-color: #333;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 500px;
}

.cardBody {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formGroup label {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.inputField,
.textareaField {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #555;
  background: #222;
  color: white;
  width: 100%;
  font-size: 1rem;
}

.inputField:focus,
.textareaField:focus {
  border-color: #999;
  outline: none;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button {
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s;
}

.button:hover {
  opacity: 0.8;
}

.whatsappContainer {
  margin-bottom: 40px;
  text-align: center;
  width: 40%;
}

.buttonwhap {
  width: 50%;
  padding: 10px 0;
}

.whatsappMessage {
  color: #ccc;
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.emailContainer {
  width: 100%;
  max-width: 500px;
  text-align: center;
}

.submitButton {
  margin-top: 10px;
  background: none;
  color: white;
  border: 1px solid #fff;
}

@media (max-width: 768px) {
  .whatsappContainer {
    margin-bottom: 40px;
    text-align: center;
    width: 70%;
  }
}

@media (max-width: 1300px) {
  .buttonwhap {
    width: 100%;
  }
}

.container {
  background-color: black; /* Fallback color for older browsers */
  color: white;
  padding: 20px;
  animation: smoothDarkGradientBackground 30s ease infinite;
  background-size: 400% 400%;
}

@keyframes smoothDarkGradientBackground {
  0% {
    background: linear-gradient(
      135deg,
      #000000,
      #0a0a0a,
      #141414,
      #1e1e1e,
      #282828
    );
    background-position: 0% 50%;
  }
  50% {
    background: linear-gradient(
      135deg,
      #282828,
      #1e1e1e,
      #141414,
      #0a0a0a,
      #000000
    );
    background-position: 100% 50%;
  }
  100% {
    background: linear-gradient(
      135deg,
      #000000,
      #0a0a0a,
      #141414,
      #1e1e1e,
      #282828
    );
    background-position: 0% 50%;
  }
}

.h1 {
  text-align: center;
  margin-bottom: 40px;
}

.cardTitle {
  font-size: 2rem;
}

.card {
  position: relative;
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #333333;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.3),
    inset 0 0 10px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.3);
  background: linear-gradient(135deg, #1a1a1a, #333333, #4d4d4d, #666666);
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1); /* Light reflection effect */
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.2); /* Inner light */
  pointer-events: none;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5),
    inset 0 0 20px rgba(0, 0, 0, 0.7), 0 10px 20px rgba(0, 0, 0, 0.5); /* Enhanced shadow on hover */
}

.cardBody {
  background-color: transparent;
  color: white !important;
  z-index: 1;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

button {
  background: rgba(255, 255, 255, 0.1); /* Transparent button background */
  color: white;
  transition: background 0.2s;
}

button:hover {
  background: rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  .buttonsContainer {
    flex-direction: column;
  }
}

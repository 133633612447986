html,
body,
#root {
  height: 100%;
  margin: 0;
  color: white !important;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.appContainer {
  min-height: 100vh; /* Set minimum height of app container to 100% of viewport height */
  background-color: #020302;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: "Kanit", sans-serif;
}
